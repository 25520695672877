var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Reports Asset Additions" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              staticStyle: { "margin-bottom": "1rem" },
                              attrs: { span: 24 }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      _vm.visible = true
                                    }
                                  }
                                },
                                [_vm._v(" Find Data")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c("TableCustom", {
                                attrs: {
                                  dataSource: _vm.dataSource,
                                  columns: _vm.columnsTable,
                                  scroll: { x: "calc(1200px + 50%)" },
                                  paginationcustom: true,
                                  defaultPagination: false,
                                  loading: _vm.loading
                                },
                                on: { "on-clickQr": _vm.handleClickQr }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "a-space",
                                {
                                  staticStyle: { height: "50px" },
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("Pagination", {
                                    attrs: {
                                      total:
                                        _vm.totalElements === 0
                                          ? 1
                                          : _vm.totalElements,
                                      pageSizeSet: _vm.limit,
                                      idPagination: "pagination1"
                                    },
                                    on: {
                                      "response-pagesize-change":
                                        _vm.responsePageSizeChange,
                                      "response-currentpage-change":
                                        _vm.responseCurrentPageChange
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              staticClass: "mt-2",
                              attrs: { span: 12, align: "end" }
                            },
                            [
                              _c(
                                "a-tag",
                                {
                                  staticStyle: { "font-size": "13px" },
                                  attrs: { color: "#8c8c8c" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_total_found")) +
                                      " : " +
                                      _vm._s(_vm.totalElements) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 24, align: "end" } },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-right": "0.5rem" },
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loadingDownload
                                  },
                                  on: { click: _vm.handleDownload }
                                },
                                [_vm._v(" Download Report")]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loadingPrint
                                  },
                                  on: { click: _vm.handlePrint }
                                },
                                [
                                  _c("a-icon", {
                                    staticStyle: { "vertical-align": "0" },
                                    attrs: { type: "printer" }
                                  }),
                                  _vm._v("Print Report")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                maskClosable: false,
                keyboard: false,
                title: "Find Data"
              },
              on: { ok: _vm.submitForm, cancel: _vm.handleCancel },
              model: {
                value: _vm.visible,
                callback: function($$v) {
                  _vm.visible = $$v
                },
                expression: "visible"
              }
            },
            [
              _c(
                "a-form",
                _vm._b(
                  {
                    attrs: { layout: "vertical", form: _vm.form },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.submitForm.apply(null, arguments)
                      }
                    }
                  },
                  "a-form",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.$t(_vm.formRules.book.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.book.decorator,
                              expression: "formRules.book.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.book.name,
                            placeholder: _vm.$t(_vm.formRules.book.placeholder),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loadingListAssetBook,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListAssetBook(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataListBook, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s("" + data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s("" + data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.$t(_vm.formRules.periode.label) }
                    },
                    [
                      _c("a-range-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.periode.decorator,
                            expression: "formRules.periode.decorator"
                          }
                        ],
                        attrs: {
                          "allow-clear": "",
                          format: _vm.DEFAULT_DATE_FORMAT,
                          placeholder: [
                            _vm.$t("lbl_start_date"),
                            _vm.$t("lbl_end_date")
                          ],
                          ranges:
                            ((_obj = {}),
                            (_obj["" + _vm.$t("lbl_this_year")] = [
                              _vm.moment().startOf("year"),
                              _vm.moment().endOf("year")
                            ]),
                            _obj)
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: {
                        label: _vm.$t(_vm.formRules.purchaseStatus.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.purchaseStatus.decorator,
                              expression: "formRules.purchaseStatus.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.purchaseStatus.name,
                            placeholder: _vm.$t(
                              _vm.formRules.purchaseStatus.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loadingPurchaseStatus,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListPurchaseStatus(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataListPurchaseStatus, function(
                          data,
                          index
                        ) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.value } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.value) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.value) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t(_vm.formRules.branch.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.branch.decorator,
                              expression: "formRules.branch.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.branch.name,
                            placeholder: _vm.$t(
                              _vm.formRules.branch.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loadingBranch,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getBranch(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                maskClosable: false,
                keyboard: false,
                title: "View QR Code",
                "ok-text": "Print QR Code",
                width: 720
              },
              on: { ok: _vm.handleOkQr },
              model: {
                value: _vm.visibleQr,
                callback: function($$v) {
                  _vm.visibleQr = $$v
                },
                expression: "visibleQr"
              }
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center"
                      },
                      attrs: { span: 24 }
                    },
                    [_c("h2", [_vm._v("Scan QR Code")])]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "-webkit-print-color-adjust": "exact" },
                  attrs: { id: "printMe" }
                },
                [
                  _vm.valueQr.length > 0
                    ? _c(
                        "a-row",
                        { staticStyle: { "margin-top": "2rem" } },
                        _vm._l(_vm.valueQr, function(dataQR, index) {
                          return _c(
                            "a-col",
                            {
                              key: index,
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center"
                              },
                              attrs: {
                                span:
                                  _vm.valueQr.length === 1
                                    ? 24
                                    : _vm.valueQr.length === 2
                                    ? 12
                                    : _vm.valueQr.length > 2
                                    ? 8
                                    : null
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "justify-content": "center",
                                    "align-items": "center",
                                    display: "flex",
                                    "flex-direction": "column"
                                  }
                                },
                                [
                                  _c("QrCode", {
                                    attrs: {
                                      value: dataQR,
                                      quality: 1,
                                      scale: 5,
                                      size: 189
                                    }
                                  }),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "font-family": "Arial, sans-serif",
                                        "font-size": "32px",
                                        "align-items": "center",
                                        "margin-bottom": "0"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(dataQR) + " ")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }