













































































































































































































































import dateFormat from "@/filters/date.filter";
import { debounceProcess } from "@/helpers/debounce";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import {
  CustomListCategory,
  DataListAssetBook,
  DataResponseReportAssetAdditions,
} from "@/models/interface/assets.interface";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataWarehouseBranch,
  DataWarehouseLocation,
} from "@/models/interface/logistic.interface";
import { assetsServices } from "@/services/assets.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { currencyFormat } from "@/validator/globalvalidator";
import moment from "moment";
import printJs from "print-js";
import Vue from "vue";

export default Vue.extend({
  name: "AssetAdditions",
  data() {
    this.getListAssetBook = debounceProcess(this.getListAssetBook, 200);
    this.getListAssetCategories = debounceProcess(
      this.getListAssetCategories,
      200
    );
    this.getListLocation = debounceProcess(this.getListLocation, 200);
    this.getListAssetStatus = debounceProcess(this.getListAssetStatus, 200);
    this.getListPurchaseStatus = debounceProcess(
      this.getListPurchaseStatus,
      200
    );
    this.getBranch = debounceProcess(this.getBranch, 200);
    return {
      DEFAULT_DATE_FORMAT,
      params: {} as RequestQueryParamsModel,
      bookId: "" as string,
      loadingSearch: false as boolean,
      loadingListAssetCategory: false as boolean,
      loadingListAssetBook: false as boolean,
      loadingLocation: false as boolean,
      loadingDownload: false as boolean,
      loading: false as boolean,
      loadingPrint: false as boolean,
      loadingStatus: false as boolean,
      loadingPurchaseStatus: false as boolean,
      loadingBranch: false as boolean,
      valueQr: [] as string[],
      dataListBook: [] as DataListAssetBook[],
      dataListCategory: [] as CustomListCategory[],
      dataListLocation: [] as DataWarehouseLocation[],
      dataListStatus: [] as ResponseListMaster[],
      dataListPurchaseStatus: [] as ResponseListMaster[],
      dataBranch: [] as DataWarehouseBranch[],
      visible: false as boolean,
      visibleQr: false as boolean,
      totalElements: 0 as number,
      limit: 10 as number,
      page: 1 as number,
      form: this.$form.createForm(this, { name: "reportAssetAdditions" }),
      formRules: {
        book: {
          label: "lbl_book",
          name: "book",
          placeholder: "lbl_book_placeholder",
          decorator: [
            "book",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        periode: {
          label: "lbl_period",
          name: "periode",
          placeholder: "lbl_period_placeholder",
          decorator: [
            "periode",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        purchaseStatus: {
          label: "lbl_purchase_status",
          name: "purchaseStatus",
          placeholder: "lbl_purchase_status_placeholder",
          decorator: [
            "purchaseStatus",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        branch: {
          label: "lbl_branch",
          name: "branch",
          placeholder: "lbl_branch_placeholder",
          decorator: [
            "branch",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      dataSource: [] as DataResponseReportAssetAdditions[],
      columnsTable: [
        {
          title: this.$t("lbl_asset_number"),
          dataIndex: "assetNo",
          key: "assetNo",
          width: 150,
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          width: 250,
        },
        {
          title: this.$t("lbl_equipment"),
          dataIndex: "equipment",
          key: "equipment",
          width: 250,
        },
        {
          title: this.$t("lbl_merk"),
          dataIndex: "merk",
          key: "merk",
          width: 250,
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
          key: "type",
          width: 250,
        },
        {
          title: this.$t("lbl_model"),
          dataIndex: "model",
          key: "model",
          width: 250,
        },
        {
          title: this.$t("lbl_sn"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: 250,
        },
        {
          title: this.$t("lbl_spec"),
          dataIndex: "specification",
          key: "specification",
          width: 150,
        },
        {
          title: this.$t("lbl_cost"),
          dataIndex: "assetCost",
          key: "assetCost",
          width: 150,
        },
        {
          title: this.$t("lbl_unit"),
          dataIndex: "unit",
          key: "unit",
          width: 150,
        },
        {
          title: this.$t("lbl_depre_exp_account"),
          dataIndex: "depreciationAccountDesc",
          key: "depreciationAccountDesc",
          width: 200,
        },
        {
          title: this.$t("lbl_acquisition_date"),
          dataIndex: "acquisitionDate",
          key: "acquisitionDate",
          width: 150,
        },
        {
          title: this.$t("lbl_life_months"),
          dataIndex: "lifeMonths",
          key: "lifeMonths",
          width: 150,
        },
        {
          title: this.$t("lbl_nbv"),
          dataIndex: "netBookValue",
          key: "netBookValue",
          width: 250,
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "assetStatus",
          key: "assetStatus",
          width: 250,
        },
        {
          title: this.$t("lbl_purchase_status"),
          dataIndex: "purchaseStatus",
          key: "purchaseStatus",
          width: 250,
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
          width: 250,
        },
        {
          title: this.$t("lbl_warehouse"),
          dataIndex: "assetLocationDesc",
          key: "assetLocationDesc",
          width: 250,
        },
      ],
    };
  },
  methods: {
    moment,
    getBranch(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
      this.loadingBranch = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(response => {
          this.dataBranch = response.data;
        })
        .finally(() => (this.loadingBranch = false));
    },
    getListPurchaseStatus(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        name: `ASSET_ADDITION_STATUS`,
      } as RequestQueryParamsModel;

      if (valueSearch) params.search = `value~*${valueSearch}*`;

      this.loadingPurchaseStatus = true;
      masterServices
        .listMaster(params)
        .then(data => {
          this.dataListPurchaseStatus = data;
        })
        .finally(() => (this.loadingPurchaseStatus = false));
    },
    handleOkQr() {
      this.visibleQr = false;
      const params: RequestQueryParamsModel = {
        unitCodes: this.valueQr.join(","),
      };
      assetsServices.getPrintQr(params).then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          printJs(url);
        }
      });
    },
    handleClickQr(params) {
      const { value } = params;
      this.visibleQr = true;
      this.valueQr = [value];
    },
    getListAssetStatus(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        name: `ASSET_STATUS`,
      } as RequestQueryParamsModel;

      if (valueSearch) params.search = `value~*${valueSearch}*`;

      this.loadingStatus = true;
      masterServices
        .listMaster(params)
        .then(data => {
          this.dataListStatus = data;
        })
        .finally(() => (this.loadingStatus = false));
    },
    handleDownload() {
      if (this.bookId) {
        this.form.validateFields((err, res) => {
          if (err) return;
          this.loadingDownload = true;
          const params: RequestQueryParamsModel = {
            startPeriod: res.periode[0].format(DEFAULT_DATE_FORMAT),
            endPeriod: res.periode[1].format(DEFAULT_DATE_FORMAT),
          };
          params.search = this.dynamicSearch(res);
          params.params = this.checkParams(res);
          assetsServices
            .getDownloadReportAssetAdditions(params, this.bookId)
            .then(data => {
              if (data) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  "reports_list_asset_additions.xlsx"
                ); //or any other extension
                document.body.appendChild(link);
                link.click();
              }
            })
            .finally(() => (this.loadingDownload = false));
        });
      }
    },
    handlePrint() {
      if (this.bookId) {
        this.form.validateFields((err, res) => {
          if (err) return;
          this.loadingPrint = true;
          const params: RequestQueryParamsModel = {
            startPeriod: res.periode[0].format(DEFAULT_DATE_FORMAT),
            endPeriod: res.periode[1].format(DEFAULT_DATE_FORMAT),
          };
          params.search = this.dynamicSearch(res);
          params.params = this.checkParams(res);
          assetsServices
            .getPrintReportAssetAdditions(params, this.bookId)
            .then(data => {
              if (data) {
                const url = window.URL.createObjectURL(new Blob([data]));
                printJs(url);
              }
            })
            .finally(() => (this.loadingPrint = false));
        });
      }
    },
    getListLocation(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch)
        params.search = `warehouse.branchWarehouse.name~*${valueSearch}*_OR_warehouse.branchWarehouse.code~*${valueSearch}*_OR_warehouse.branchWarehouse.address~*${valueSearch}*_OR_warehouse.name~*${valueSearch}*`;
      this.loadingLocation = true;
      logisticServices
        .listWarehouseLocation(params, "")
        .then(data => {
          this.dataListLocation = data.data;
        })
        .finally(() => (this.loadingLocation = false));
    },
    getListAssetCategories(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch)
        params.search = `categoryId~*${valueSearch}*_OR_description~*${valueSearch}*_OR_lifeMonths~${valueSearch}`;
      this.loadingListAssetCategory = true;
      assetsServices
        .listAssetCategory(params)
        .then(response => {
          let tempDataSegments = [] as CustomListCategory[];
          response.data.forEach(data => {
            let tempSegment = {
              id: data.id,
              accumulatedDepreciationAccountId: `${
                data.accumulatedDepreciationAccount.code
                  ? data.accumulatedDepreciationAccount.code
                  : ""
              }-${data.accumulatedDepreciationAccount.description}`,
              depreciationAccount: `${
                data.depreciationExpenseAccount.code
                  ? data.depreciationExpenseAccount.code
                  : ""
              }-${data.depreciationExpenseAccount.description}`,
              first: "",
              second: "",
              idForView: "",
            } as CustomListCategory;

            let idCategories = data.category.id.split(".");

            idCategories = idCategories.map(dataMap => {
              let temp = dataMap.split("");
              if (temp.length > 0) {
                temp[0] = temp[0].toUpperCase();
                dataMap = temp.join("");
              }
              return dataMap;
            });

            tempSegment.idForView = idCategories.join(".");
            tempDataSegments.push(tempSegment);
          });
          this.dataListCategory = tempDataSegments;
        })
        .finally(() => (this.loadingListAssetCategory = false));
    },
    getListAssetBook(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `name~*${valueSearch}*`;
      this.loadingListAssetBook = true;
      assetsServices
        .listAssetBook(params)
        .then(data => {
          this.dataListBook = data.data;
        })
        .finally(() => (this.loadingListAssetBook = false));
    },
    responsePageSizeChange(response: ResponsePagination) {
      this.limit = response.size;
      this.page = 1;
      this.submitForm();
    },
    responseCurrentPageChange(response: ResponsePagination) {
      this.page = response.page;
      this.submitForm();
    },
    handleCancel() {
      this.form.resetFields();
    },
    assignSearch(key, value, and): string {
      if (key === "purchaseStatus" && value)
        return and + `purchaseStatus~${value}`;
      else if (key === "branch" && value)
        return (
          and + `assetLocation.warehouse.branchWarehouse.secureId~${value}`
        );
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    checkValue(value): string {
      if (value && !value.includes("undefined")) {
        return value;
      } else {
        return ",ALL";
      }
    },
    checkParams(res): string {
      let params = "";
      // company is mandatory
      params += this.$store.state.authStore.authData.companyName;
      params += this.checkValue(
        `,${
          this.dataBranch.find(dataFind => dataFind.id === res["branch"])?.name
        }`
      );
      params += this.checkValue(
        `,${
          this.dataListBook.find(dataFind => dataFind.id === res["book"])?.name
        }`
      );
      params += this.checkValue(
        `,${res["periode"][0].format(DEFAULT_DATE_FORMAT)}`
      );
      params += this.checkValue(
        `,${res["periode"][1].format(DEFAULT_DATE_FORMAT)}`
      );
      params += this.checkValue(`,${res["purchaseStatus"]}`);
      return params;
    },
    submitForm() {
      this.form.validateFields((err, res) => {
        if (err) return;
        let params = {
          limit: 10,
          page: this.page - 1,
          startPeriod: res.periode[0].format(DEFAULT_DATE_FORMAT),
          endPeriod: res.periode[1].format(DEFAULT_DATE_FORMAT),
        } as RequestQueryParamsModel;
        params.search = this.dynamicSearch(res);
        this.loading = true;
        assetsServices
          .getReportAssetAdditions(params, res.book)
          .then(data => {
            this.totalElements = data.totalElements;
            data.data.forEach((dataMap, index) => {
              dataMap.key = index;
              dataMap.acquisitionDate = dataMap.acquisitionDate
                ? moment(dataMap.acquisitionDate).format("DD-MMM-yyyy")
                : "";
              dataMap.assetCost = currencyFormat(dataMap.assetCost);
              dataMap.depreciationAmount = currencyFormat(
                dataMap.depreciationAmount
              );
              dataMap.netBookValue = currencyFormat(dataMap.netBookValue);
            });
            this.params = params;
            this.bookId = res.book;
            this.dataSource = data.data;
            this.visible = false;
          })
          .finally(() => (this.loading = false));
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created() {
    this.getListAssetBook("");
    this.getListAssetCategories("");
    this.getListLocation("");
    this.getListAssetStatus("");
    this.getListPurchaseStatus("");
    this.getBranch("");
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
